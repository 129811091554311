<template>
  <div id="index" class="screens">
    <div>
      <div class="video-box" >
        <!-- <video
          v-if="showvideo"
          class="video-bg"
          autoplay
          muted
          src="https://llcb.oss-cn-shanghai.aliyuncs.com/fdyqd/dev/202402/bj2024020110020679618.mp4"
          loop
          :style="videoStyle"
        ></video> -->
        <div class="bg-box">
          <img class="bg-img" src="@/assets/pageBg.png" alt="">
        </div>

        <!-- <video muted autoplay src="http://sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/mp4/xgplayer-demo-360p.mp4"></video> -->
        <!-- <div class="mb">
        </div> -->
      </div>
      <!-- 456 460 -->
      <dv-full-screen-container >
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else class="pd-20">
          <div style="width: 100%;height: 14vh" class="flex space-between">
            <div class="back" @click="back()">
              <img src="@/assets/back.png" alt="">
            </div>
            <div class="project-name leftTopW" style="height: 60px" >
              <dv-decoration-3  style="width:250px;height:30px;" />
              <div class="project-titles">{{titleInfo.engineeringName}}</div>
            </div>
            <div class="po-re centerW" >
              <div class="title po-ab">{{titleInfo.screenTitle}}</div>
              <dv-decoration-5 :color="['#7ce7fd','#7ce7fd', ]" style="width:100%;height:40px;margin-top:30px" :dur="3" />
            </div>
            <div class="address leftW" style="height: 80px">
              <dv-decoration-7 style="height:80px;">
                <span>{{titleInfo.areaName}}</span>
                <span>{{titleInfo.statisticDate}}</span>
              </dv-decoration-7>
            </div>
          </div>
          <div class="flex contents">

            <div class="leftW">
              <div style="width: 100%;height: 42vh">
                <dv-border-box-12 class="position-re">
                  <div class="left-name">今日在职工人工种统计</div>
                  <div id="chartsTop1" class="chart-top">
                  </div>
                </dv-border-box-12>
              </div>
              <div style="width: 100%;height: 42vh">
                <dv-border-box-12>
                  <div class="left-name">今日出勤工人工种统计</div>
                  <div id="chartsTop2" class="chart-top ">
                  </div>
                </dv-border-box-12>
              </div>
            </div>

            <div class="centerW">
              <div style="width: 100%;height: 14vh">
                <dv-border-box-12 :reverse="true">
                  <div class="center-top flex space-around item-center">
                    <div class="40w">
                      <div class="number green">{{currAttendanceData.totalUserNum}}</div>
                      <div class="name">在职工人数</div>
                    </div>
                    <div class="40w">
                      <div class="number green">{{currAttendanceData.totalManagerUserNum}}</div>
                      <div class="name">项目管理人数</div>
                    </div>
                    <div class="40w">
                      <div class="number green">{{currAttendanceData.attendanceUserNum}}</div>
                      <div class="name">工人出勤人数</div>
                    </div>
                    <div class="40w">
                      <div class="number orange">{{currAttendanceData.leaveUserNum}}</div>
                      <div class="name">工人请假人数</div>
                    </div>
                    <div class="40w">
                      <div class="number orange">{{currAttendanceData.restUserNum}}</div>
                      <div class="name">工人休息人数</div>
                    </div>
                    <div class="40w">
                      <div class="number orange">{{currAttendanceData.unAttendanceUserNum}}</div>
                      <div class="name">未出勤工人数</div>
                    </div>
                    <div class="40w">
                      <div class="number orange">{{currAttendanceData.attendanceRate}}%</div>
                      <div class="name">工人出勤率</div>
                    </div>
                  </div>
                </dv-border-box-12>
              </div>
              <div style="width: 100%;height: 35vh">
                <dv-border-box-12 :reverse="true">
                  <div style="height:35vh">
                    <div class="left-name">最近十四日出勤率趋势图(项目管理人员+施工工人)</div>
                    <div id="chartCenter1" class="chart-centers"></div>
                  </div>
                </dv-border-box-12>
              </div>
              <div style="width: 100%;height: 35vh">
                <dv-border-box-12 :reverse="true">
                    <div style="height:35vh">
                      <div class="left-name">参建单位今日出勤情况</div>
                      <div class="chart-center" v-if="showtable">
                        <div  class="tableBox">
                          <div class="flex tabletitle swiper-slide-tabs2">
                            <div class="tableTh tableTh-name">参建单位名称</div>
                            <div class="tableTh" style="width: 88px;">在职人数</div>
                            <div class="tableTh" style="width: 88px;">请假人数</div>
                            <div class="tableTh" style="width: 88px;">出勤人数</div>
                            <div class="tableTh" style="width: 88px;">出勤率</div>
                            <div class="tableTh" style="width: 88px;">近7日出勤率</div>
                          </div>
                          <div v-if="tableData.length > 0" id="swiper-containers"  class="tableScroll swiper-container">
                            <div class="swiper-wrapper">
                              <div v-for="(item,index) in tableData" :key="index" :class="(index%2)==0?'swiper-slide1 swiper-slide-tabs2':'swiper-slide1  swiper-slide-tabs1'" >
                                <div class="content flex" >
                                  <div class="content-txt content-txt-name">{{item.companyName}}</div>
                                  <div class="content-txt">{{item.totalUserNum}}</div>
                                  <div class="content-txt">{{item.restUserNum}}</div>
                                  <div class="content-txt">{{item.attendanceUserNum}}</div>
                                  <div class="content-txt">{{item.attendanceRate}}%</div>
                                  <div class="content-txt">{{item.historyAttendanceRate}}%</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div v-else class="tableScroll"> -->
                            <!-- <img class="noImg" src="../../assets/screen/noData.png" alt=""> -->
                          <!-- </div> -->
                        </div>

                        <!-- <dv-scroll-board :config="config" style="width:100%;height:29vh" /> -->
                      </div>
                    </div>
                  </dv-border-box-12>
              </div>
            </div>

            <div class="rightW">
              <div style="width: 100%;height: 14vh">
                <dv-border-box-12 reverse="true">
                  <div style="height:35vh">
                    <div class="left-name">项目处罚记录</div>
                    <div  class="chart-center1 flex">
                      <div class="log-txt">
                        <div class="cfcolor ml-10 cfst">{{todayTotal.workerCount}}</div>
                        <div class="mt-10 cfname">工人处罚记录</div>
                      </div>
                      <div class="log-txt">
                        <div class="cfcolor ml-10  cfst">{{todayTotal.managerCount}}</div>
                        <div class="mt-10 cfname">项目部人员处罚记录</div>
                      </div>
                    </div>
                  </div>
                </dv-border-box-12>
              </div>

              <div style="width: 100%;height: 35vh">
                <dv-border-box-12>
                  <div class="left-name">今日要求与整改单统计</div>
                  <div  class="tableBox2">
                    <div class=" tabletitle swiper-slide-tabs2">
                      <div class="tableTh21">开单人</div>
                      <div class="tableTh22">第一责任人</div>
                      <div class="tableTh23">原因</div>
                    </div>
                    <div v-if="tableData2.length > 0" id="swiper-containers2"  class="tableScroll2 swiper-container">
                      <div class="swiper-wrapper" >
                        <div v-for="(item,index) in tableData2" :key="index" :class="(index%2)==0?'swiper-slide swiper-slide-tabs2':'swiper-slide  swiper-slide-tabs1'" >
                          <div class="content2 " >
                            <div class="content-txt21">{{item.createName}}</div>
                            <div class="content-txt22">{{item.receiverName}}</div>
                            <div class="content-txt23">{{item.content}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
<!--                    <div v-else class="tableScroll3">
                      &lt;!&ndash; <img class="noImg" src="../../assets/screen/noData.png" alt=""> &ndash;&gt;
                    </div>-->
                  </div>

                    <!-- <div class="chart-center">
                      <dv-scroll-board
                        class="dv-scroll-style"
                        v-if="showtable2"
                        :config="config2"
                        style="width:100%;height:29vh" />
                    </div> -->
                </dv-border-box-12>
              </div>
              <div style="width: 100%;height: 35vh">
                <dv-border-box-12  ref="borderBox">
                <div class="left-name">近三日人员动态记录</div>
                  <div id='chartRight' class="chart-right">
                  </div>
                </dv-border-box-12>
              </div>
            </div>
          </div>
        </div>
      </dv-full-screen-container>
    </div>
  </div>
</template>
<script>
import moment from "moment"
import Swiper from 'swiper'
let echarts = require('echarts/lib/echarts')
import {
  screenTitleInfo,
  currAttendanceInfo,
  inJobTypeWork,
  attendanceTypeWork,
  attendanceTrend,
  participateCompanyAttendanceList,
  todayPunishTotal,
  todayReformTotal,
  personnelDynamics,
 } from "../../services/construction.js";

export default {
  data() {
    return {
      intervalId: null,//是否定时刷新
      setTimeoutId1:null,//定时器ID
      setTimeoutId2:null,//定时器ID
      showvideo:true,
      loading:true,
      videoStyle:"",
      data1:[],
      data2:[],
      dataName1:[],
      dataName2:[],
      objData1:[],
      objData2:[],
      dataCenter:[], //center 值
      dataCenter1:[], //center 值
      dataCenterTime:[], //center 时间
      dataTemplate:[],
      config:{
        header:[
          "参建单位名称",
          "在职人数",
          "请假人数",
          "出勤人数",
          "出勤率",
          "近7日出勤率",
        ],
        data:[],
        columnWidth:[250],
        // headerBGC:"#181d3b",
        headerBGC:"rgba(24,29,59,0.5)",
        // oddRowBGC:"#092b4d",
        oddRowBGC:"rgba(9,43,77,0.5)",
        // evenRowBGC:"#8484184",
        evenRowBGC:"rgba(132,132,132,0.5)",
      },
      config2:{
        header:[
          "开单人",
          "第一责任人",
          "原因",
        ],
        data:[
        ],
        columnWidth:[80,100,],
        columnHeight:[200],
        // headerBGC:"#181d3b",
        headerBGC:"rgba(24,29,59,0.5)",
        // oddRowBGC:"#092b4d",
        oddRowBGC:"rgba(9,43,77,0.5)",
        // evenRowBGC:"#8484184",
        evenRowBGC:"rgba(132,132,132,0.5)",
      },

      rightDataX:[
      ],
      rightDataY1:[],
      rightDataY2:[],
      titleInfo:"",
      todayTotal:"",
      currAttendanceData:"",
      dataTemplate2:"",
      showtable:false,
      showtable2:false,
      tableData:[],
      tableData2:[],
      loop:false,
      loop2:false,
      mySwiper1:null,
      mySwiper2:null,

    }
  },
  created(){
    setTimeout(() => {
      this.cancelLoading()
    }, 100)

    // setInterval(()=>{
    //   this.showvideo = !this.showvideo
    // },5000)
  },
  beforeDestroy() {
    // 清除定时器
    if (this.intervalId) {
        clearInterval(this.intervalId);
    }
  },
  mounted(){
    this.resizeVideo()
    window.addEventListener("resize", () => {
      this.resizeVideo()
    });
    //数据首次加载
     setTimeout(()=>{
        this.dataInit();
    },1000)
      //数据定时刷新
      this.intervalId = setInterval(()=>{
          // 清除定时器
          if (this.setTimeoutId1) {
              clearInterval(this.setTimeoutId1);
          }
          // 清除定时器
          if (this.setTimeoutId2) {
              clearInterval(this.setTimeoutId2);
          }
        this.dataInit();
    },1728000000)
  },
  methods:{
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    screenTitleInfoFn(){
      screenTitleInfo({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.titleInfo = res.data
      })
      todayPunishTotal({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.todayTotal = res.data
      })
      currAttendanceInfo({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.currAttendanceData = res.data
      })
      participateCompanyAttendanceList({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        if(res.code == 200){
        this.tableData = res.data


        // console.log(this.tableData)
        // res.data.forEach(item=>{
        //   let arr = []
        //   arr[0] = item.companyName
        //   arr[1] = item.totalUserNum
        //   arr[2] = item.restUserNum
        //   arr[3] = item.attendanceUserNum
        //   arr[4] = item.attendanceRate+"%"
        //   arr[5] = item.historyAttendanceRate+"%"
        //   this.dataTemplate.push(arr)
        // })
        // this.config.data = this.dataTemplate
        // this.config.data = this.config.data.concat(this.dataTemplate)

        // let obj = {
        //     "companyId": 105,
        //     "companyName": "河南城乡职业技能培训学校有限公司",
        //     "statisticType": 2,
        //     "businessId": 177,
        //     "totalUserNum": 1,
        //     "attendanceUserNum": 0,
        //     "restUserNum": 0,
        //     "attendanceRate": "0",
        //     "historyAttendanceRate": "0"
        // }
        // for(var i=0;i<4;i++){
        //   // console.log(i)
        //   this.tableData.push(obj)
        // }
        if(this.tableData.length<5){
          this.loop = false
        }else{
          this.loop = true
        }
        if(this.mySwiper1) {
            this.mySwiper1.destroy();
            this.mySwiper1 = null;
        }
        this.setTimeoutId1=setTimeout(()=>{
            this.mySwiper1 = new Swiper('#swiper-containers', {
                  direction: 'vertical',
                  speed: 1000, // 速度
                  mousewheel: true, // 鼠标滚轮控制
                  autoplay: {
                    delay: 1000, //3秒切换一次
                    disableOnInteraction: true
                  },
                  loop:this.loop,
                  loopAdditionalSlides:1,
                  slidesPerView:5, // 每页显示几个slide
                })
                //鼠标覆盖停止自动切换
            this.mySwiper1.el.onmouseover = function(){
                this.mySwiper1.autoplay.stop();
                }
            this.mySwiper1.el.onmouseout = function(){
                this.mySwiper1.autoplay.start();
                }
        },100)

        this.showtable = true
        }
      })
      todayReformTotal({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.tableData2 = res.data
        // let obj = {
        //     "createName": "name",
        //     "receiverName": 2,
        //     "content": 177,
        // }
        // for(var i=0;i<20;i++){
        //   // console.log(i)

        //   this.tableData2.push(obj)
        // }
        if(this.tableData2.length<5){
          this.loop2 = false
        }else{
          this.loop2 = true
        }
          if(this.mySwiper2) {
              this.mySwiper2.destroy();
              this.mySwiper2 = null;
          }
        this.setTimeoutId2=setTimeout(()=>{
          this.$nextTick(() => {
            console.log('执行了吗',this.tableData2.length);
            this.mySwiper2 = new Swiper('#swiper-containers2', {
              direction: 'vertical',
              speed: 1000, // 速度
              mousewheel: true, // 鼠标滚轮控制
              autoplay: {
                delay: 1000, //3秒切换一次
                disableOnInteraction: true
              },
              loop:this.loop2,
              loopAdditionalSlides:1,
              slidesPerView:5, // 每页显示几个slide\
            })
            //鼠标覆盖停止自动切换
              this.mySwiper2.el.onmouseover = function(){
                  this.mySwiper2.autoplay.stop();
            }
              this.mySwiper2.el.onmouseout = function(){
                  this.mySwiper2.autoplay.start();
            }
          })
        },1000)

        // this.dataTemplate2 = []
        // res.data.forEach(item=>{
        //   let arr = []
        //   arr[0] = item.createName
        //   arr[1] = item.receiverName
        //   arr[2] = item.content
        //   this.dataTemplate2.push(arr)
        // })
        // this.config.data = this.dataTemplate
        // this.config2.data = this.config2.data.concat(this.dataTemplate2)
        this.showtable2 = true
      })

    },

    resizeVideo() {
      let screenScale = window.innerWidth / window.innerHeight;
      this.videoStyle = {
        width: 100 + (10/screenScale)  + "%",
        left: -(10/screenScale) + "%",
      }
      console.log(this.videoStyle)
    },
    array2obj1(array, key) {
      var resObj = {};
      for (var i = 0; i < array.length; i++) {
        resObj[array[i][key]] = array[i];
      }
      return resObj;
    },
    initChartsTop1(){
      let _this = this
      _this.objData1 = _this.array2obj1(_this.data1, "name");
      //  接口请求
        let myChart = echarts.init(document.getElementById('chartsTop1'));
        let option = {
            tooltip: {
              trigger: "item",
            },
            graphic: {
              elements: [
                {
                  type: "image",
                  style: {
                    width: 100,
                    height: 100,
                  },
                  left: "center",
                  top: "center",
                },
              ],
            },
            legend: {
              orient: "horizontal",
              icon: "circle",
              bottom: 0,
              x: "center",
              formatter: function (name) {
                return name +  _this.objData1[name].value ;
              },
              textStyle: {
                color: "#fff",
                fontSize:10,
              },
              data: this.dataName1,
            },
            series: [
              {
                type: "pie",
                radius: ["40%", "50%"],
                center: ["45%", "50%"],
                // color: ["#0E7CE2", "#FF8352", "#E271DE", "#F8456B", "#00FFFF", "#4AEAB0"],
                data: this.data1,
                labelLine: {
                  normal: {
                    show: true,
                    length: 0,
                    length2: 0,
                    lineStyle: {
                      color: "#12EABE",
                      width: 2,
                    },
                  },
                },
                label: {
                  normal: {
                    formatter: "",
                    // formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
                    rich: {
                      b: {
                        fontSize: 20,
                        color: "#12EABE",
                        align: "left",
                        padding: 4,
                      },
                      hr: {
                        borderColor: "#12EABE",
                        width: "100%",
                        borderWidth: 2,
                        height: 0,
                      },
                      d: {
                        fontSize: 20,
                        color: "#fff",
                        align: "left",
                        padding: 4,
                      },
                      c: {
                        fontSize: 20,
                        color: "#fff",
                        align: "left",
                        padding: 4,
                      },
                    },
                  },
                },
              },
            ],
          };
        myChart.setOption(option);
    },
    array2obj2(array, key) {
      var resObj = {};
      for (var i = 0; i < array.length; i++) {
        resObj[array[i][key]] = array[i];
      }
      console.log(resObj,"22222")
      return resObj;
    },
    initChartsTop2(){
      let _this = this
      _this.objData2 = _this.array2obj1(_this.data2, "name");
      //  接口请求
        let myChart = echarts.init(document.getElementById('chartsTop2'));
        let option = {
            tooltip: {
              trigger: "item",
            },
            graphic: {
              elements: [
                {
                  type: "image",
                  style: {
                    width: 100,
                    height: 100,
                  },
                  left: "center",
                  top: "center",
                },
              ],
            },
            legend: {
              orient: "horizontal",
              icon: "circle",
              bottom:0,
              x: "center",
              formatter: function (name) {
                return name + _this.objData2[name].value ;
              },
              textStyle: {
                color: "#fff",
              },
              data: this.dataName2,
            },
            series: [
              {
                type: "pie",
                radius: ["40%", "50%"],
                center: ["45%", "50%"],
                // color: ["#0E7CE2", "#FF8352", "#E271DE", "#F8456B", "#00FFFF", "#4AEAB0"],
                data: this.data2,
                labelLine: {
                  normal: {
                    show: true,
                    length: 0,
                    length2: 0,
                    lineStyle: {
                      color: "#12EABE",
                      width: 2,
                    },
                  },
                },
                label: {
                  normal: {
                    formatter: "",
                    // formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
                    rich: {
                      b: {
                        fontSize: 20,
                        color: "#12EABE",
                        align: "left",
                        padding: 4,
                      },
                      hr: {
                        borderColor: "#12EABE",
                        width: "100%",
                        borderWidth: 2,
                        height: 0,
                      },
                      d: {
                        fontSize: 20,
                        color: "#fff",
                        align: "left",
                        padding: 4,
                      },
                      c: {
                        fontSize: 20,
                        color: "#fff",
                        align: "left",
                        padding: 4,
                      },
                    },
                  },
                },
              },
            ],
          };
        myChart.setOption(option);
    },

    initChartsCenter1(){
      // let _this = this
      let myChart = echarts.init(document.getElementById('chartCenter1'));
      let option = {
        grid: {
          left: 10,
          top: 30,
          bottom: 20,
          right: 40,
          containLabel: true,
        },
         // 悬停框颜色
        tooltip: {
          show: true,
          backgroundColor: "#7ce7fd",
          borderColor: "#7ce7fd",
          borderWidth: 1,
          formatter: "{b}:（{c}%）",
          extraCssText: "box-shadow: 0 0 5px rgba(0, 0, 0, 1)",
        },
        legend: {
          right: 0,
          top: 0,
          data: ["距离"],
          textStyle: {
            color: "#5c6076",
          },
        },
        xAxis: {
          data: this.dataCenterTime,
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#324670",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          ayisLine: {
            show: false,
          },
          axisLabel: {
            formatter: "{value}%",
             //  y 轴 日期颜色
            textStyle: {
              color: "#324670",
            },
          },
          splitLine: {
            show: true,
             // 背景图横条
            lineStyle: {
              color: "#192744",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#384157",
            },
          },
        },

        series: [
          {
            type: "bar",
            tooltip: {
              show: false,
            },
            animation: false,
            barWidth: 1.4,
            hoverAnimation: false,
            data: this.dataCenter,
            itemStyle: {
              normal: {
                color: "#7ce7fd",
                opacity: 0.6,
                label: {
                  show: false,
                },
              },
            },
          },
          {
            type: "line",
            name: "linedemo",
            smooth: true,
            symbolSize: 10,
            animation: false,
            lineWidth: 1.2,
            hoverAnimation: false,
            data: this.dataCenter,
            symbol: "circle",
            itemStyle: {
               // 动态折线图颜色
              normal: {
                color: "#69FFFD",
                shadowBlur: 40,
                label: {
                  show: true,
                  position: "top",
                  formatter: '{c}'+'%',
                  textStyle: {
                    color: "#81AFE4",
                    fontSize: 12
                  },
                },
              },
            },
            areaStyle: {
              normal: {
                color: "#7ce7fd",
                opacity: 0.08,
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initChartsRight(){
      let myChart = echarts.init(document.getElementById('chartRight'));
      let option = {
          grid:[{
              //show:true,                       //是否显示
              //zlevel:0,                        //所属图形的Canvas分层，zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
              //z:2,                              //所属组件的z分层，z值小的图形会被z值大的图形覆盖
              left:5,                      //组件离容器左侧的距离,百分比字符串或整型数字
              top:30,                          //组件离容器上侧的距离，百分比字符串或整型数字
              right:"auto",                    //组件离容器右侧的距离,百分比字符串或整型数字
              bottom:50,                   //组件离容器下侧的距离,百分比字符串或整型数字
              width:"90%",                    //图例宽度
              height:"auto",                   //图例高度
              containLabel: true,               //grid 区域是否包含坐标轴的刻度标签，
          }],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          bottom: "5%",
          data: ["入职", "离职"],
        },
        toolbox: {
          show: true,
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: this.rightDataX,
            axisLabel: {
            //  x 轴 日期颜色
              textStyle: {
                color: "#324670",
              },
            },

          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              //  y 轴 日期颜色
              textStyle: {
                color: "#324670",
                  fontSize:'10px',
              },
            },
            splitLine: {
              show: true,
              // 背景图横条
              lineStyle: {
                color: "#192744",
              },
            },

          },
        ],
        series: [
          {
            name: "入职",
            type: "bar",
            data: this.rightDataY1,
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: function (params) {
                  return params.data.value;
                },
                textStyle: {
                  color: "#fff",
                },
              },
            },

          },
          {
            name: "离职",
            type: "bar",
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: function (params) {
                  return params.data.value;
                },
                textStyle: {
                  color: "#81AFE4",
                },
              },
            },
            data: this.rightDataY2,
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#2485FF",
                  },
                  {
                    offset: 1,
                    color: "#2485FF",
                  },
                ]),
                barBorderRadius: 50,
                borderWidth: 0,
              },
            },

          },
        ],

      }
      myChart.setOption(option);
    },
    back(){
      this.$router.go(-1)
    },
      //数据自动刷新接口
     dataInit(){
         //  标题接口
         this.screenTitleInfoFn();
         inJobTypeWork({
             businessId:sessionStorage.getItem('engineeringSecondId'),
         }).then(res=>{
             console.log(res)
             if(res.code === 200){
                 this.dataName1 = []
                 res.data.forEach(item=>{
                     item.value = item.count
                     item.name = item.varietiesName
                     this.dataName1.push(item.name)
                 })
                 this.data1 = res.data
                 this.initChartsTop1()
             }
         })

         attendanceTypeWork({
             businessId:sessionStorage.getItem('engineeringSecondId'),
         }).then(res=>{
             console.log(res)
             if(res.code === 200){
                 this.dataName2 = []
                 res.data.forEach(item=>{
                     item.value = item.count
                     item.name = item.varietiesName
                     this.dataName2.push(item.name)
                 })
                 this.data2 = res.data
                 console.log(this.dataName2,this.data2)
                 this.initChartsTop2()
             }
         })

         attendanceTrend({
             businessId:sessionStorage.getItem('engineeringSecondId'),
         }).then(res=>{
             if(res.code == 200){
                 this.dataCenterTime = res.data.statisticKeyList
                 this.dataCenter = res.data.statisticValueList
                 this.dataCenter1 = res.data.statisticValueList
                 this.initChartsCenter1()
             }
         })
         personnelDynamics({
             businessId:sessionStorage.getItem('engineeringSecondId'),
         }).then(res=>{
             if(res.code == 200){
                 this.rightDataX = []
                 this.rightDataY1 = []
                 this.rightDataY2 = []
                 res.data.forEach(item=>{
                     //console.log("----------进三日人员动态----"+item.statisticDate+"----"+moment(item.statisticDate).format('MM-DD'));
                     this.rightDataX.push(moment(item.statisticDate).format('MM-DD'))
                     this.rightDataY1.push(item.entryUserNum)
                     this.rightDataY2.push(item.leaveUserNum)
                 })

                 this.initChartsRight()
             }
         })
     },
  }
}
</script>

<style scoped lang="less">
  .back{
    width: 50px;
    height: 50px;
    img{
      width: 50px;
      height: 50px;
    }
  }
  #index{
    width: 100%;
    height: 100vh;
    // background: red !important;

  }
  .video-bg{
    position: fixed;
    top: 0;
    // left: -5%;
    width: 110%;
    z-index: 1;
  }
  .video-bgs{
    position: fixed;
    top: 0;
    // // left: -5%;
    // width: 110%;
    // z-index: 0;
  }
  .center-top{
    height: 14vh;
    padding: 0 0.5rem;
  }
  .flex{
    display: flex;

  }
  .space-between{
    justify-content: space-between;
  }
  .space-around{
    justify-content: space-around;
    align-items: center;
  }
  .item-center{
    align-items: center;
  }

  .title{
    font-size: 0.36rem;
    color: #ffffff;
    font-weight: 700;
    width: 100%;
    text-align: center;
    line-height:1.3rem;
  }
  .project-name{
    font-size: 0.22rem;
    color: #ffffff;
    font-weight: 500;
    line-height:1.5rem;
    text-align: left;
    margin-left: 0.25rem;
  }
  .address{
    font-size: 0.14rem;
    color: #ffffff;
    font-weight: 500;
    text-align: right;
    margin-top: 0.5rem;
  }
  .po-re{
    position: relative;
  }
  .po-ab{
    position: absolute;
  }
  .pd-20{
    padding: 0 0.2rem;
  }
  .whleft1{
    width: 4.56rem;
    height: 3.80rem;
  }
  .number{
    font-size: 0.24rem;
    color: #fff;
    font-weight: 600;
  }
  .name{
    font-size: 0.14rem;
    color: #fff;
    margin-top: 0.10rem;
  }
  .w40{
    width: 25%;
  }
  .green{
    color: #64F7FD;
  }
  .orange{
    color: #FFA643;
  }
.left-name{
  font-size: 0.16rem;
  color: #fff;
  text-align: left;
  padding: 0.25rem 0.15rem 0 0.25rem ;
  box-sizing: border-box;
  // margin-top: 0.25rem;
}
.chart-center1{
  width: 950px;
  height: 32vh;
  margin-top: 0.08rem;
  // padding: 0 0.2rem;
}

.chart-top{
  width: 100%;
  height: 38vh;
  margin-top: -0.6rem;
  // margin-left: 0.1rem;
}
.chart-center{
  // width: 51.5%;
  height: 30vh;
  margin-top: 0.1rem;
  padding: 0 0.2rem;
}

.chart-centers{
  // width: 51.5%;
  height: 30vh;
  margin-top: 0.1rem;
  padding: 0.01rem 0.2rem;
}
.log-txt{
  font-size: 0.18rem;
  width: 220px;
  text-align: left;
  color: #fff;
  margin-top: 0.1rem;
  text-align: center;
}
.red{
  color: red;
}
.cfcolor{
  color: #FF4848;
}
.cfname{
  font-size: 0.12rem;
}
.cfst{
  font-size: 0.24rem;
}

.ml-10{
  margin-left: 0.1rem;
}
.chart-right{
  margin: 0 auto;
  width:90%;
  height: 31vh;
}
.position-re{
  position: relative;
}
.bgred{
  position: absolute;
  bottom: 0.1rem;
  left: 50%;
  margin-left: -210px;
}
.wrap{
  flex-wrap: wrap;
}
.legend{
  width: 200px;
  height: 30px;
  background: red;
  display: flex;

  font-size: 0.18rem;
  .icon-color{
    width: 15px;
    height: 15px;
    background: yellow;
  }
}
.video-box{
  position: relative;
}
.mb{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(31,32,32,0.5);

}
.bg-box{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .bg-img{
    width: 100%;
    height: 100%;
  }
}
.tableBox{
  width: 9.2rem;
}
.tableBox2{
  width: 4.2rem;
  margin: 0 auto;
}
.tabletitle{
  display: flex;
  justify-content: flex-start;
  height: 0.4rem;
  line-height: 0.4rem;
  // color: #ffffff;
  // font-weight: bold;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.screen {
  min-height: 100%;
  padding: 10px;
}


.tableTh{
  font-size:0.18rem;
  color: #2E6099;
}
.tableTh21{
  width:0.55rem;
  font-size:0.16rem;
  color: #2E6099;
  margin-right: 0.2rem;
}
.tableTh22{
  width: 0.95rem;
  font-size:0.16rem;
  color: #2E6099;
}
.tableTh23{
  width: 0.6rem;
  font-size:0.16rem;
  color: #2E6099;
}
.tableTh-name{
  width: 3rem;
}
// 大于1024
.tableScroll{
  height:4.34rem;
  overflow: hidden;
  position: relative;
}
.tableScroll2{
  height:2.2rem;
  overflow: hidden;
  position: relative;
}
.flex{
  display: flex;
  justify-content: space-between;
}

.tableScroll{
  height: 2.52rem;
  overflow: hidden;
}

.tableScroll3{
  height:1.8rem;
  overflow: hidden;
  .noImg{
    height:1.8rem;
  }
}
.content{
  // height: .62rem;
  // line-height: .62rem;
  // width: 8.00rem;
  padding: 0 .2rem 0 .18rem;
  margin: 0 auto 0 .06rem;
  align-items: center;
  font-size: .14rem;
  color: #D0FFFD;
}
.content2{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 .2rem 0 .18rem;
  margin: 0 auto 0 .06rem;
  align-items: center;
  font-size: .14rem;
  color: #D0FFFD;
}
.content-txt{
  // padding: 0 0.2rem;
  box-sizing: border-box;
  //background: red;
  width: 80px;

}

.content-txt21{
  // padding: 0 0.2rem;
  width:0.55rem;
  line-height:0.44rem;
  margin-right: 0.2rem;
  box-sizing: border-box;
  display:-webkit-box;
	overflow:hidden;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:1;  //显示两行
}
.content-txt22{
  // padding: 0 0.2rem;
  width: 0.95rem;
  line-height:0.44rem;
  box-sizing: border-box;
  display:-webkit-box;
	overflow:hidden;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:1;  //显示两行
}
.content-txt23{
  // padding: 0 0.2rem;
  width:2.1rem;

  line-height:0.44rem;
  // height:0.52rem ;
  // line-height: 0.3rem;
  box-sizing: border-box;
  // display:-webkit-box;
	// overflow:hidden;
	// -webkit-box-orient:vertical;
	// -webkit-line-clamp:1;  //显示两行
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-txt-name{
  width: 2.8rem;
  // display:-webkit-box;
	// overflow:hidden;
	// -webkit-box-orient:vertical;
	// -webkit-line-clamp:1;  //显示两行
}
// .content:hover{
//   background: url('../../assets/screen/tableHover.png') center center no-repeat;
//   background-size: 8.32rem .64rem;
// }

.contents{
  width: 100%;
}
.leftW{
  width: 24.2%;
}
.centerW{
  width: 51.5%;
}
.rightW{
  width: 24.2%;
}
.leftTopW{
  width: 18%;
}
  .swiper-slide1 p,.swiper-slide1{
    height: .5rem;
    line-height: .5rem;
    color: #fff;
  }
.swiper-slide p,.swiper-slide{
  height: .44rem;
  line-height: .44rem;
  color: #fff;
}
.swiper-slide-tabs1{
  background: #145095;
}
.swiper-slide-tabs2{
  background: #060d1c;
}
</style>
<style >
.dv-scroll-style .rows .ceil{
  white-space:wrap;
  overflow:hidden;
  line-height: 25px;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}
.project-titles{
  height: 100px;
  display:-webkit-box;
	overflow:hidden;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:1;
}
.mt-10{
  margin: 0.1rem;
}

</style>
