import { request,METHOD } from '@/utils/request.js'
const apiList = {
  screenTitleInfo: '/business/attendanceDailyStatistics/screenTitleInfo', // 大屏配置及工程信息
  currAttendanceInfo: '/business/attendanceDailyStatistics/currAttendanceInfo', // 工程/项目今日出勤统计
  inJobTypeWork: '/business/attendanceDailyStatistics/inJobTypeWork', // 今日在职工人工种统计
  attendanceTypeWork: '/business/attendanceDailyStatistics/attendanceTypeWork', //今日出勤工人工种统计
  attendanceTrend: '/business/attendanceDailyStatistics/attendanceTrend', // 工程/项目最近14日出勤率趋势图
  participateCompanyAttendanceList: '/business/attendanceDailyStatistics/participateCompanyAttendanceList', // 参建单位今日出勤情况列表
  todayPunishTotal: '/business/attendanceDailyStatistics/todayPunishTotal', // 参建单位今日出勤情况列表
  todayReformTotal: '/business/attendanceDailyStatistics/todayReformTotal', // 今日要求与整改单统计
  personnelDynamics: '/business/attendanceDailyStatistics/personnelDynamics', // 最近3日人员动态柱状图
}

/**
 * 应用模块列表
 * @param [string] status 搜索-状态
 */
export function screenTitleInfo(parameter) {
  return request({
    url: apiList.screenTitleInfo,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function currAttendanceInfo(parameter) {
  return request({
    url: apiList.currAttendanceInfo,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function inJobTypeWork(parameter) {
  return request({
    url: apiList.inJobTypeWork,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function attendanceTypeWork(parameter) {
  return request({
    url: apiList.attendanceTypeWork,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function attendanceTrend(parameter) {
  return request({
    url: apiList.attendanceTrend,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function participateCompanyAttendanceList(parameter) {
  return request({
    url: apiList.participateCompanyAttendanceList,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function todayPunishTotal(parameter) {
  return request({
    url: apiList.todayPunishTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function todayReformTotal(parameter) {
  return request({
    url: apiList.todayReformTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
export function personnelDynamics(parameter) {
  return request({
    url: apiList.personnelDynamics,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
